import React, {useState} from "react";
import '../App.css'
import { CreatorPage } from "./CreatorPage";
import axios from "axios";

function LoginPage() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [locked, setLocked] = useState(true);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const url = `https://backendtdsr-istudorbusiness.b4a.run/blogs/login/uname=${username}`;
        // const url = 'http://localhost:3001/blogs/login/uname=' + username;
        const result = await axios.get(url, {
            headers: {
                "password" : password
            }
        });

        console.log(result);
        console.log(result.data === 200);

        // alert("Ajunge aici? : " + result);
        if(result.data === 200) setLocked(false);
        else alert("Invalid login");
    }

    const followUsername = (e: any) => {
        setUsername(e.target.value);
    }

    const followPassword = (e: any) => {
        setPassword(e.target.value);
    }

    return (
        <div>
            {locked.valueOf() === true
             ?
            <div>
                <br></br>
                <div className="container simple-section p-4">
                    <form onSubmit={handleSubmit}>
                        <h1>Login page</h1>
                        <br></br>
                        <p>Please log in before proceeding to the creator page</p> 
                        <br></br>
                        <label htmlFor="username">Insert username</label>
                        <br></br>
                        <input type="text" onChange={followUsername} id="username" name="username"></input>
                        <br></br>
                        <label htmlFor="password">Insert password</label>
                        <br></br>
                        <input type="password" onChange={followPassword} id="password" name="password"></input>
                        <br></br>
                        <br></br>
                        <button className="btn btn-success">Log in</button>
                        <br></br>
                    </form>
                </div>
            </div>
            :
            <CreatorPage username={username} />
            }
        </div>
    )
}

export {
    LoginPage
}