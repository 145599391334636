// imports

import "./Footer.scss";

import "react-lazy-load-image-component/src/effects/blur.css";
import "aos/dist/aos.css";
import { HashLink } from "react-router-hash-link";

// constants
let linkedIn = "https://www.linkedin.com/company/solarracing/mycompany/";
let instagram = "https://www.instagram.com/topdutchsolarracing/";
let facebook = "https://www.facebook.com/topdutchsolarracing";
let twitter = "https://twitter.com/tdsolarracing";
let youtube = "https://www.youtube.com/channel/UCujnrOD35tMp0dCDoJp5qUw";
let newTab = "_blank";

let bestuur = "bestuur@solarracinggroningen.nl";
let acquisition = "acquisition@solarracinggroningen.nl";
let comms = "pers@solarracinggroningen.nl";

const Footer = () => {
  return (
    <footer
      data-aos="fade-down"
      className="footer-general container-fluid footer"
    >
      <div className="footer-options ">
        <div className="footer-options-icons row justify-content-around">
          <div className="footer-contact col-md-4  d-flex flex-column align-content-around text-center">
            <p>Contact Us</p>
            <div className="footer-contact-special">
              <a
                className=" mail"
                href="mailto:bestuur@solarracinggroningen.nl"
              >
                <text className="emphasis"> /Bestuur</text> {bestuur}
              </a>
              <br></br>

              <a
                className="mail"
                href="mailto:acquisition@solarracinggroningen.nl"
              >
                <text className="emphasis"> /Acquisition</text> {acquisition}
              </a>
              <br></br>
              <a className=" mail" href="mailto:pers@solarracinggroningen.nl">
                {" "}
                <text className="emphasis"> /Communication</text> {comms}
              </a>
            </div>
          </div>
          <div className="footer-contact col-md-4  d-flex flex-column align-content-around text-center">
            <p>Address</p>
            <div className="footer-contact-special">
              <a className=" mail" href="https://goo.gl/maps/FDftEgUEMXhbBMcz9">
                <text className="emphasis"> /Top Dutch Solar Racing</text>{" "}
                Zernikelaan 17, 9747 AA Groningen
              </a>
              <br></br>
            </div>
          </div>
          <div className="footer-social col-md-4 d-flex flex-column align-content-around text-center">
            <p>Social Media</p>
            <div>
              <a href={facebook} class="fa fa-facebook" target={newTab}></a>
              <a href={instagram} class="fa fa-instagram" target={newTab}></a>
              <a href={twitter} class="fa fa-twitter" target={newTab}></a>
              <a href={linkedIn} class="fa fa-linkedin" target={newTab}></a>
              <a href={youtube} class="fa fa-youtube" target={newTab}></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        Ⓒ 2023 Top Dutch Solar Racing - Software Engineering Department
        <br></br>
        <HashLink onClick={() => {
          window.location.href = "https://solarracing.nl/privacy-policy";
        }}>
          Privacy Policy
        </HashLink>
        <br></br>
        <HashLink onClick={() => {
          window.location.href = "https://solarracing.nl/terms-and-conditions-en";
        }}>
          Terms and Conditions(EN)
        </HashLink>
        <br></br>
        <HashLink onClick={() => {
          window.location.href = "https://solarracing.nl/terms-and-conditions-nl";
        }}>
          Terms and Conditions(NL)
        </HashLink>
      </div>
    </footer>
  );
};

export default Footer;
