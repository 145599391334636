import { idText } from "typescript";
import { Blog } from "./blog";

function toInt(str : string) : number {
    if(str === '') return 1;
    return parseInt(str);
}

function getFormValues(formData: FormData): Blog  {
    const header = formData.get('header') as string;
    const body = formData.get('body') as string;
    const date_time = Date.now().toString();
    const images = formData.get('images') as string;
    const category = formData.get('category') as string;
    const poster = formData.get('poster') as string || "";
    const videoID = formData.get('videoID') as string || "";
 
    //console.log(images);

    const imagesArray = images.split(',');
    console.log(imagesArray);
    //for(let idx = 0; idx < imagesArray.length; idx ++) saveImgArray[idx] = saveImgArray[idx].trim();

    console.log("Content:")

    // TODO: Fix this later
    // console.log(imagesArray.length);
    // console.log(imagesArray)

    let blog: Blog = {
        id: 69420,
        date_time: date_time,
        header: header,
        body: body,
        images: imagesArray,
        category: category,
        poster: poster,
        videoID: videoID
    }

    return blog;
}

export {
    getFormValues
}