import React, { FormEventHandler } from "react";
import { Link } from "react-router-dom";
import { PostForm } from "../utils/PostForm";
import { Blog } from "../utils/blog";
import { getFormValues } from "../utils/form";
import axios from 'axios';

function CreatorPage({username}: any) {
    const getFreshId = async () : Promise<number> => {
        const url = "https://backendtdsr-istudorbusiness.b4a.run/blogs/number";
        // const url = "http://localhost:3001/blogs/number"
        const id: number = await axios.get(url).then((id) => id.data);
        return id;
    }

    const postBlog = async (blog : Blog) => {
        const url = `https://backendtdsr-istudorbusiness.b4a.run/blogs/creator/id=${blog.id}`;
        // const url = 'http://localhost:3001/blogs/creator/id=' + blog.id;
        console.log(url);
        try {
            const res = await axios.post(url, blog);
            const data : number = res.data;
            console.log(data);
        } catch (err) {
            console.log("Something went wrong with creating the blog");
        }
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) : Promise<void> {
        event.preventDefault();
        // alert("If you have problems afterwards show this id to Andrei :)   ID:" + response_id.data);
        // console.log(event);
        // console.log(event.target);

        // Here post the form
        const formData = new FormData(event.currentTarget);
        let blog : Blog = getFormValues(formData);

        blog.id = await getFreshId();
        console.log(blog.id, blog.date_time, blog.header, blog.body, blog.category, blog.images);    
        
        await postBlog(blog);
        alert("Blog post submitted :)\nYou can now return to the main page and check if it really worked!");
    }

    return (
        <>
        <div className="container simple-section">
            <div className="row simple-section-header creator-title">
                <h1>Welcome to the creator page - 
                    <Link to='/'>
                        <button className="btn btn-success">
                            Return
                        </button>
                    </Link>
                </h1>
                <h3>Current profile: <strong>{username?.toString().replace(/([A-Z])/g, ' $1').trim()}</strong></h3>
            </div>
        </div>
        <div className="container simple-section">
            <div className="row simple-section-header">
                {/* Instructions */}
                <h3>Add a new post to our blog:</h3>
                <h4>(In order to resize and compress images properly go to <a href="https://resizing.app/features/compress-jpeg/" target="_blank">this website</a>)</h4>
                <br></br>
                <br></br>
                <h5><strong>UPDATE #1:</strong> Now you can write text in bold and in italic. For doing so, use the tags &lt;b&gt; ... some text ... &lt;b /&gt; for bolding the text in between the tags and &lt;i&gt; ... some text ... &lt;i /&gt; for the italic font in between the tags. Don't forget, once you open a tag you also have to close it! <br></br> If you forgot, talk to Andrei and he can fix it in no time :) </h5>
                <h5><strong>UPDATE #2:</strong> Now you can also place images in between text. To do so, place tags &lt;image1&gt;, &lt;image2&gt;, &lt;image3&gt;, etc. depending on the number of images that you have. I recommend placing them in between paragraphs, so that the text doesn't suffer any formatting errors. </h5>
                <h5><strong>NOTE ABOUT IMAGES:</strong> Please do Andrei a favour and <strong>don't upload images bigger than 8-10 MB</strong>. Please, Please, Please... Only if it's really needed ;) </h5>
                <br></br>
                <h5><strong>IMPORTANT:</strong> If you have any questions, contact Andrei!!!</h5>
            </div>
            <div className="row simple-section-body">
                <PostForm onSubmit={handleSubmit} username={username}></PostForm>
            </div>
        </div>
        </>
    )
}

export {
    CreatorPage
};