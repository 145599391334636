import "./Navbar.scss";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../resources/tdsr-full-logo-green.svg";
import Button from "react-bootstrap/Button";
import { HashLink } from "react-router-hash-link";

function Navbar_blurred() {
  return (
    <Navbar sticky="top" className="blurry " expand="lg">
      <Container>
        <Navbar.Brand href="/blogs">
          <HashLink className="dropItem" onClick={() => {
              window.location.href = "https://solarracing.nl/";
            }}>
            <img
              src={logo}
              width="120"
              height="40"
              className="d-inline-block align-top"
              alt="TDSR logo"
            />
          </HashLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav ml-auto" />
        <Navbar.Collapse>
          <Nav className="ml-auto align-it">
            <NavDropdown className="main" title="Teams" id="basic-nav-dropdown">
              <NavDropdown.Item href="">
                <HashLink className="dropItem" onClick={() => {
                    window.location.href = "https://solarracing.nl/team23";
                  }}>
                  Team 23
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <HashLink className="dropItem" onClick={() => {
                    window.location.href = "https://solarracing.nl/team21";
                  }}>
                  Team 21{" "}
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <HashLink className="dropItem" onClick={() => {
                    window.location.href = "https://solarracing.nl/team19";
                  }}>
                  Team 19{" "}
                </HashLink>
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="main"
              title="Challenges"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="">
                <HashLink
                  className="dropItem"
                  onClick={() => {
                    window.location.href = "https://solarracing.nl/challenges#iESC22";
                  }}
                >
                  iLumen European Solar Challenge 2022
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <HashLink
                  className="dropItem"
                  onClick={() => {
                    window.location.href = "https://solarracing.nl/challenges#Morocco";
                  }}
                >
                  Moroccan Solar Challenge 2021
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <HashLink
                  className="dropItem"
                  onClick={() => {
                    window.location.href = "https://solarracing.nl/challenges#iESC20";
                  }}
                >
                  iLumen European Solar Challenge 2020
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="https://solarracing.nl">
                <HashLink
                  className="dropItem"
                  onClick={() => {
                    window.location.href = "https://solarracing.nl/challenges#Australia";
                  }}
                >
                  Bridgestone World Solar Challenge 2019
                </HashLink>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="main" title="Cars" id="basic-nav-dropdown">
              <NavDropdown.Item href="">
                <HashLink
                  className="dropItem"
                  onClick={() => {
                    window.location.href = "https://solarracing.nl/cars#GreenSpirit";
                  }}
                >
                  Green Spirit
                </HashLink>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                <HashLink
                  className="dropItem"
                  onClick={() => {
                    window.location.href = "https://solarracing.nl/cars#GreenLightning";
                  }}
                >
                  Green Lightning
                </HashLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="main" href="https://solarblogs.nl/">
              Blogs
            </Nav.Link>
            <Nav.Link className="main" href="https://solarracing.nl/partners">
              Partners
            </Nav.Link>
            <Nav.Link className="main" href="https://solarracing.nl/contact">
              Contact
            </Nav.Link>
            <Nav.Link className="main" href="https://solarracing.nl/about">
              About Us
            </Nav.Link>
            <Nav.Link className="main" href="http://eepurl.com/dNAm4U">
              <Button className="newsletter">Join the newsletter!</Button>{" "}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar_blurred;
