import React, { useEffect } from 'react';
import './custom.scss';
import { BlogsList } from './routes/BlogPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CreatorPage } from './routes/CreatorPage';
import SharedLayout from './utils/SharedLayout/SharedLayout';

import Aos from 'aos';
import {LoginPage} from './routes/LoginPage';
import { IndividualBlog } from './routes/IndividualBlog';

function App() {
  useEffect(() => {
    Aos.init();
  })

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <br></br>
          <Routes>
            <Route path='/' element={<SharedLayout />}>
              <Route path='/' element={<BlogsList />}></Route>
              <Route path='/blogs' element={<BlogsList />}></Route>
              <Route path='/blog/:id' element={<IndividualBlog />}></Route>
              <Route path='/login' element={<LoginPage />}></Route>
            </Route>
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
