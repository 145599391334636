import React, { useState } from 'react';
import styled from 'styled-components';

interface ICarouselProps {
  images: string[];
}

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 20px;

  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 500px;
`;

const Button = styled.button`
  margin: 8px 8px;
`;

const Carousel: React.FC<ICarouselProps> = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  if (images.length === 0) {
    return null;
  }

  const handleNext = () => {
    const nextIndex = currentImageIndex + 1;
    setCurrentImageIndex(nextIndex >= images.length ? 0 : nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = currentImageIndex - 1;
    setCurrentImageIndex(prevIndex < 0 ? images.length - 1 : prevIndex);
  };

  return (
    <CarouselWrapper>
      <CarouselContainer>
        {images.length > 1 && <Button onClick={handlePrev} className='btn btn-success'>Prev</Button>}
        <ImageWrapper>
          <Image src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
        </ImageWrapper>
        {images.length > 1 && <Button onClick={handleNext} className='btn btn-success'>Next</Button>}
      </CarouselContainer>
    </CarouselWrapper>

  );
};

export default Carousel;