import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
// import { Carousel } from "react-responsive-carousel";
import fetch from "node-fetch";
import { Link } from "react-router-dom";
import Aos from "aos";
import axios from "axios";
import Carousel from "../utils/Images/Carousel";
import YoutubeEmbed from "../utils/YoutubeVideos";
import "./BlogPage.scss";

// Default image for blog without thumbnail
import defaultImage from "../images/tdsr_default.jpg";

function stripTags(text) {
  // Remove specified tags
  let cleanedText = text.replace("<b>", "").replace("</b>", "").replace("<i>", "").replace("</i>", "").replace(/<image\d*>/g, "");
  
  // Remove empty lines before the first paragraph
  return cleanedText.replace(/^\s*\n+/g, "");
}

function BlogsList() {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(0);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    let url = "https://backendtdsr-istudorbusiness.b4a.run/blogs?";
    // let url = "http://localhost:3001/blogs?";
    if (name !== "") url += `name=${name}`;
    if (page !== 0) url += `&page=${page}`;
    const result = await fetch(url)
      .then((response) => {
        return response.json();
      })
      .then(function (data) {
        return JSON.stringify(data);
      });
    const aux = JSON.parse(result);
    setBlogs(aux);
    setIsLoading(false);
    return aux;
  };
  const getNumber = async () => {
    const url = "https://backendtdsr-istudorbusiness.b4a.run/blogs/number";
    // const url = "http://localhost:3001/blogs/number";
    const id = await axios.get(url).then((response) => response.data);
    return id;
  };

  const setStartingPage = async () => {
    await setPage(0);
  };

  const nextPage = async () => {
    const res = await getNumber();
    if ((page + 1) * 9 <= res) await setPage(page + 1);
    console.log(page);
  };

  const previousPage = async () => {
    if (page > 0) await setPage(page - 1);
    console.log(page);
  };

  useEffect(() => {
    fetchData();
    Aos.init();
  }, [name, page]);

  return (
    <>
      <div
        className="control-panel container-blogs"
        data-aos="flip-left"
        data-aos-duration="1000"
      >
        <div className="row panel">
          <div className="column">
            <label className="ml-3 mr-3 space-right">Search</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Title, Post or Category"
              onChange={(e) => {
                setName(e.currentTarget.value);
              }}
            />
          </div>
        </div>
        <div className="row panel">
          <div className="column">
            <button onClick={previousPage} className="btn btn-success btn-blog">
              Previous
            </button>
            <button onClick={nextPage} className="btn btn-success btn-blog">
              Next
            </button>
          </div>
        </div>
      </div>

      
      {isLoading ? (
        <div className="d-flex justify-content-center flex-column align-items-center">
          <Spinner animation="border" style={{ color: '#198754' }} />
          <p className="mt-2">The blogs are loading...</p>
        </div>  
      ) : (
        <>
      <Row className="max-width d-flex align-items-center blogs-display">
        {blogs &&
          blogs.map((blogObject) => (
            <Col md={4} key={blogObject.id} className="mb-4 ">
              <Card className="card-centered">
                <div className="card-img-container fixed-img-size">
                  {
                    blogObject.images.length > 0 ?
                      <Card.Img variant="top" src={blogObject.images[0]} />
                      :
                      <Card.Img variant="top" src={defaultImage} />
                  }
                </div>
                <Card.Header>
                  <Card.Title>{blogObject.header.substring(0, 50)}...</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* <Card.Text className="card-text">
                    {blogObject.body.substring(0, 200)}...
                  </Card.Text> */}
                  <Card.Text className="card-text" style={{ whiteSpace: "pre-line" }}>
                    {stripTags(blogObject.body).substring(0, 200) + "..."}
                  </Card.Text>
                  {/* <Card.Text className="card-text">
                    {stripTags(blogObject.body).substring(0, 200)}...
                  </Card.Text> */}
                  <footer className="blockquote-footer">
                    Posted by:{" "}
                    {/* Add LinkedIns for the posters */}
                    <cite title="Source Title">{blogObject.poster}</cite> on{" "}
                    <cite title="Source Title">{(new Date(blogObject.date_time).toISOString().split('T')[0])}</cite>
                  </footer>
                  <Button variant="success" href={"/blog/" + blogObject.id}>
                    Read blog
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
      <div className="control-panel container">
        <div className="row panel">
          <div className="column">
            <button onClick={previousPage} className="btn btn-success btn-blog">
              Previous
            </button>

            <button onClick={nextPage} className="btn btn-success btn-blog">
              Next
            </button>
          </div>
        </div>
      </div>
      </>
      )}
    </>
  );
}

export { BlogsList };
