import React, { FormEventHandler, useState } from "react";
import { Blog } from "./blog";
import ImageUploading, { ImageListType } from 'react-images-uploading';

// Installed by "react-uploader".
import { Uploader } from "uploader";
import { UploadDropzone } from "react-uploader";
import styled from "styled-components";

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Initialize once (at the start of your app).
const uploader = Uploader({ apiKey: "public_FW25bVkDL8CQaZXzxjcybHxQevHA" }); // Your real API key.
const uploaderOptions = {
  multi: true,

  // Comment out this line & use 'onUpdate' instead of
  // 'onComplete' to have the dropzone close after upload.
  showFinishButton: true,

  styles: {
    colors: {
      primary: "#198754"
    }
  }
}

interface UpdateBlogFormProps {
    Blog?: Blog,
    onSubmit: FormEventHandler<HTMLFormElement>,
    username?: string
}

interface File {
    fileUrl: string;
}

function PostForm({Blog, onSubmit, username} : UpdateBlogFormProps) {
    const [text, setText] = useState();
    const [title, setTitle] = useState();
    const [category, setCategory] = useState();
    const [images, setImages] = useState([]);
    const [save, setSave] = useState([]);
    const [show, setShow] = useState(true);
    const [poster, setPoster] = useState(username);
    const [videoID, setVideoID] = useState('')
    const imageDataURLs: string[] = [];
    const maxNumber = 10;

    const handleUpdate = (files: File[]) => {
        const urls = files.map((x: File) => x.fileUrl);
        setImages(urls as never[]);
        console.log(images);
      };

    const followHeader = (event : any) => {
        setTitle(event.target.value);
    }

    const followBody = (event : any) => {
        setText(event.target.value);
    }

    const followCategory = (event : any) => {
        setCategory(event.target.value);
    }

    const followPoster = (event : any) => {
        setPoster(event.target.value);
    }

    const followVideoID = (event : any) => {
        setPoster(event.target.value);
    }

    return (
        <>
        {/* imagini între texte și preprocesare */}
        { show.valueOf() == true ?
        <form onSubmit={onSubmit}>
            <label htmlFor="poster">This blog will be posted by:</label>
            <br></br>
            <input type="text" defaultValue={poster?.toString().replace(/([A-Z])/g, ' $1').trim()} id="poster" name="poster" onChange={followPoster} />

            <br/>

            <label htmlFor="header">Blog title *</label>
            <br></br>

            {/* Bold and cursive for titles inside the text */}
            <textarea defaultValue={Blog && Blog.header} onChange={followHeader} name="header" id="header" rows={1} cols={100}  />

            <br/>

            <label htmlFor="body">Blog text *</label>
            <br></br>
            <textarea defaultValue={Blog && Blog.body} onChange={followBody} name="body" id="body" rows={10} cols={100} />

            <br/>

            <label htmlFor="category">Blog category - used for sections</label>
            <br></br>
            <input type="text" defaultValue={category} id="category" name="category" onChange={followCategory} />

            <br />

            <label htmlFor="videoID">Youtube Video - Add the id after "v=" in a youtube link</label>
            <br></br>
            <input type="text" defaultValue={videoID} id="videoID" name="videoID" onChange={followVideoID} />

            <br />

            <label htmlFor="images">Images</label>
            <br></br>
            <textarea defaultValue={images.toString()} id="images" name="images" rows={10} cols={50} hidden={true}/>
            <CenteredWrapper>
                <UploadDropzone
                    uploader={uploader}
                    options={uploaderOptions}
                    onUpdate={handleUpdate}
                    onComplete={files => alert("Files uploaded successfully! <3")}
                    width="600px"
                    height="375px"
                />
            </CenteredWrapper>
            <br />
            <br />
            <br />
            {/* Disable button when files not submitted */}
            <button type="submit" className="btn btn-success" >Submit post</button>
        </form>
        :
        <div>
            <h3>Blog posted successfully</h3>
            <h4>Now you can press the "Return" button and go back to the main page to check if everything worked.</h4>
        </div>
        }
        </>
    )
    
}

export {
    PostForm
};