import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import YoutubeEmbed from "../utils/YoutubeVideos";
import Carousel from "../utils/Images/Carousel";

function renderStyledBody(body, images) {
    const elements = [];
    let currentPos = 0;
    
    const italicReg = /<i>(.*?)<\/i>/g;
    const boldReg = /<b>(.*?)<\/b>/g;
    const imageReg = /<image(\d+)>/g;

    const pushSubstring = (start, end, type, imageIndex) => {
        const text = body.substring(start, end);
        if (type === 'i') {
            elements.push(<em key={start}>{text}</em>);
        } else if (type === 'b') {
            elements.push(<strong key={start}>{text}</strong>);
        } else if (type === 'image') {
            if (imageIndex >= 0 && imageIndex < images.length && images[imageIndex]) {
                // elements.push(<img key={start} src={images[imageIndex]} alt={`Image ${imageIndex}`} />);
                elements.push(
                    <img 
                        key={start} 
                        src={images[imageIndex]} 
                        alt={`Image ${imageIndex}`} 
                        style={{ 
                            width: '100%', 
                            height: 'auto', 
                            maxWidth: '800px', 
                            display: 'block', 
                            marginLeft: 'auto', 
                            marginRight: 'auto',
                            marginTop: '1rem' 
                        }} 
                    />
                );
            } else {
                console.error(`Invalid image index: ${imageIndex}. Ensure <imageX> tags are correctly numbered and match the images array.`);
            }
        } else {
            elements.push(text);
        }
    };

    let matchI, matchB, matchImage;
    
    while (currentPos < body.length) {
        italicReg.lastIndex = currentPos;
        boldReg.lastIndex = currentPos;
        imageReg.lastIndex = currentPos;
        matchI = italicReg.exec(body);
        matchB = boldReg.exec(body);
        matchImage = imageReg.exec(body);

        const nextMatch = [matchI, matchB, matchImage].filter(Boolean).sort((a, b) => a.index - b.index)[0];
        
        if (!nextMatch) {
            pushSubstring(currentPos, body.length);
            break;
        }

        pushSubstring(currentPos, nextMatch.index);  // Push text before the next match

        if (nextMatch === matchI) {
            pushSubstring(matchI.index + 3, italicReg.lastIndex - 4, 'i');  // +3 and -4 adjust for <i> and </i> lengths
            currentPos = italicReg.lastIndex;
        } else if (nextMatch === matchB) {
            pushSubstring(matchB.index + 3, boldReg.lastIndex - 4, 'b');  // +3 and -4 adjust for <b> and </b> lengths
            currentPos = boldReg.lastIndex;
        } else if (nextMatch === matchImage) {
            pushSubstring(matchImage.index, imageReg.lastIndex, 'image', parseInt(matchImage[1]) - 1);
            currentPos = imageReg.lastIndex;
        }
    }

    return elements;
}



function IndividualBlog() {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();

    const fetchData = async () => {
        let url = "https://backendtdsr-istudorbusiness.b4a.run/blogs/id=";
        // let url = "http://localhost:3001/blogs/id=";
        if(id !== '') url += id;
        else url += 0;
        console.log(url)
        const result = await fetch(url)
            .then((response) => {
                return response.json();
            })
            .then(function (data) {
                return JSON.stringify(data);
            });
        const aux = JSON.parse(result);
        console.log(aux);
        // alert(aux);
        setBlog(aux);
        return aux;
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="container simple-section mt-5 mb-3 text-left">
                { blog && 
                <>
                    <div className="row simple-section-header p-4">
                        <h2>{blog.header}</h2>
                        <h5>
                            <span className="inlineBlockOnMobile">{(new Date(blog.date_time).toISOString().split('T')[0])}</span>
                            <span className="tab inlineBlockOnMobile" />
                            <span className="inlineBlockOnMobile">{blog.category !== "" ? (blog.category) : "General"}</span>
                            <span className="tab inlineBlockOnMobile" />
                            <span className="inlineBlockOnMobile">Posted by: {blog.poster}</span>
                        </h5>
                    </div>
                    <div className="row simple-section-body" style={{whiteSpace: "normal"}}>
                        {/* <p style={{whiteSpace: "pre-line", overflow: "auto", wordWrap: "break-word", textAlign: "left"}}>{blog.body}</p> */}
                        {<p style={{whiteSpace: "pre-line", overflow: "auto", wordWrap: "break-word", textAlign: "left"}}>{renderStyledBody(blog.body, blog.images)}</p>}
                        {
                            (blog.images.valueOf().toString() === ",undefined" ?
                            <></>
                            :
                                (
                                    /<image\d+>/.test(blog.body) ? 
                                        <></> 
                                        :
                                        <Carousel images={blog.images}/>
                                )
                            )
                            // blog.images.map((image) => <img src={image} className="halfsize"/>)
                        }
                    </div>
                    <div className="row simple-section-body">
                        {blog.videoID === "" ?
                            <></>
                            :
                            <YoutubeEmbed embedId={blog.videoID}></YoutubeEmbed>  
                        } 
                    </div>
                    </>
                    }
            </div>    
        </>
    )
};

export {
    IndividualBlog
};